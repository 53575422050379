<template>

  <h2>Account settings</h2>

  <section>
    <h5>EMAIL &amp; USERNAME</h5>
    <form>
      <FormInput formName="accountSettingsForm" fieldName="email" />
      <FormInput formName="accountSettingsForm" fieldName="username" />
    </form>
  </section>

  <section ref="changePasswordFormElement">
    <h5>PASSWORD</h5>
    <form :class="{ 'disabled': isAPIReadOnly }" @submit="submitChangePasswordForm">
      <LoadingOverlay v-if="changePasswordForm.isLoading" />
      <FormSubmitErrors formName="changePasswordForm" />
      <FormInput formName="changePasswordForm" fieldName="currentPassword" />
      <FormInput formName="changePasswordForm" fieldName="password" />
      <PasswordRequirements :password="changePasswordForm.fields.password.value" />
      <FormInput formName="changePasswordForm" fieldName="passwordConfirmation" />
      <button type="submit" class="small" :disabled="isAPIReadOnly || changePasswordForm.isLoading || !isChangePasswordFormValid">Change password</button>
    </form>
  </section>

</template>

<script>

  import { mapState } from 'vuex'

  import useForm from '@/composables/useForm'

  import FormInput from '@/components/forms/FormInput.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'
  import PasswordRequirements from '@/components/forms/PasswordRequirements.vue'

  export default {
    components: {
      FormInput,
      LoadingOverlay,
      FormSubmitErrors,
      PasswordRequirements,
    },
    setup() {

      // composables
      const { form: changePasswordForm, isFormValid: isChangePasswordFormValid } = useForm({ formName: 'changePasswordForm' })

      return {
        changePasswordForm,
        isChangePasswordFormValid,
      }

    },
    computed: {
      ...mapState('user', ['user']),
      ...mapState('app', ['isAPIReadOnly']),
    },
    data() {
      return {
        keypressCounter: 0,
        keypressTimeout: null,
      }
    },
    created() {
      this.$store.commit('forms/SET_FIELD_VALUE', { formName: 'accountSettingsForm', fieldName: 'email', newValue: this.user.email })
      this.$store.commit('forms/SET_FIELD_VALUE', { formName: 'accountSettingsForm', fieldName: 'username', newValue: this.user.username })

      this.$store.commit('forms/SET_FIELD_DISABLED', { formName: 'changePasswordForm', fieldName: 'password', newValue: this.isAPIReadOnly })
      this.$store.commit('forms/SET_FIELD_DISABLED', { formName: 'changePasswordForm', fieldName: 'currentPassword', newValue: this.isAPIReadOnly })
      this.$store.commit('forms/SET_FIELD_DISABLED', { formName: 'changePasswordForm', fieldName: 'passwordConfirmation', newValue: this.isAPIReadOnly })
    },
    mounted() {
      document.addEventListener('keydown', this.onKeyDown)
    },
    beforeUnmount() {
      document.removeEventListener('keydown', this.onKeyDown)
    },
    methods: {
      scrollToTopOfChangePasswordForm() {
        if (!this.$refs.changePasswordFormElement) return
        this.$refs.changePasswordFormElement.scrollIntoView({ behavior: 'smooth' })
      },
      submitChangePasswordForm($event) {

        if ($event) $event.preventDefault()

        if (this.isAPIReadOnly) return

        this.$store.dispatch('forms/SUBMIT_FORM', 'changePasswordForm')
          .then((result) => {
            this.$store.commit('forms/RESET_FORM', 'changePasswordForm')
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Your password has been changed!',
              type: 'success',
            })
          })
          .catch(() => {
            // do nothing
            this.scrollToTopOfChangePasswordForm()
          })

      },
      onKeyDown(event) {

        if (event.code !== 'Escape') return

        this.keypressCounter += 1
        clearTimeout(this.keypressTimeout)

        if (this.keypressCounter >= 5) {
          this.keypressCounter = 0
          this.$store.dispatch('modals/OPEN_MODAL', { name: 'SecretSettingsModal' })

        } else {
          this.keypressTimeout = setTimeout(() => {
            this.keypressCounter = 0
          }, 500)
        }

      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/account-page.styl'

</style>
